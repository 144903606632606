import React from 'react';
import { Router } from '@reach/router'

import Layout from '../containers/Layout'
import EditPage from '../containers/EditPage'

export default () => {
  return (
    <Layout>
      <Router>
        <EditPage path="/edit/:slug/:answerId" />
      </Router>
    </Layout>
  );
}

import React, { createElement, PureComponent } from 'react';
import { compose } from 'redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { string, object } from 'yup';
import { navigate } from 'gatsby';
import get from 'lodash/get';
import toPairs from 'lodash/toPairs';

import Container from '../components/Container'
import Text from '../components/Text'
import Box from '../components/Box'
import Input from '../components/Input'
import Button from '../components/Button'

import { selectFirestore } from '../services/firebase/selectors';
import { withConnect } from '../stores/surveyStore';

const fields = [
  { name: 'name', label: '姓名' },
  { name: 'email', label: 'E-mail' },
]

const schema = object().shape({
  name: string().required(),
  email: string().email().required(),
})

const keyMaps = {
  '2019-intern': {
    name: '4-0',
    email: '4-1',
  },
}

class EditPage extends PureComponent {
  handleSubmit = (values, { setFieldError }) => {
    const { slug, myAnswer, readSurvey, answerId } = this.props;
    const keyMap = keyMaps[slug];
    if (keyMap) {
      const passed = toPairs(keyMap).reduce((test, [key, index]) => {
        const res = values[key] === get(myAnswer, index);
        if (!res) setFieldError(key, '資料不符合');
        return test && res;
      }, true);
      if (passed) {
        readSurvey({ slug, answers: myAnswer, answerId });
        navigate(`/${slug}/1`);
      }
    }
  }

  render() {
    return (
      <Container py="2em">
        <Text.h1>更新問卷</Text.h1>
        <Text my="1em">在更新問卷答案之前，先讓我們確定你的身分</Text>
        <Formik validationSchema={schema} onSubmit={this.handleSubmit}>
          <Form>
            {fields.map(({ name, label }) => (
              <Box key={name} my="1em">
                <Text my="0.5em">{label}</Text>
                <Field name={name}>
                  {({ field }) => <Input {...field} />}
                </Field>
                <ErrorMessage name={name}>
                  {msg => <Text fontSize="0.8em" mt="0.5em" color="red">{msg}</Text>}
                </ErrorMessage>
              </Box>
            ))}
            <Box mt="2em">
              <Button type="submit">送出</Button>
            </Box>
          </Form>
        </Formik>
      </Container>
    );
  }
}

export default (props) => createElement(compose(
  selectFirestore([{
    collection: 'answers',
    doc: props.answerId,
    storeAs: 'myAnswer',
  }]),
  withConnect(props.slug),
)(EditPage), props);
